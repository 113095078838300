@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "upgrade";
  font-weight: 400;
  src: url("https://use.typekit.net/sob5jke.css");
}



input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  left: -96%;

}

input::-webkit-datetime-edit {
  position: relative;
  left: 10px;
  color: #9BA3AF;
  
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 15px;
  font-weight: 100;
  
}


/* input field for accepting only numbers*/
input::-webkit-outer-spin-button,

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


/* body {
  position: fixed;
  height: 100%;
  min-height: fill-available;
  min-height: -webkit-fill-available;
} */

/* height: calc(100vh - calc(100vh - 100%)) */

/* html {
  height: fill-available;
  height: -webkit-fill-available;
} */

/* .layout {
  min-height: 100vh;           
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
} */




@layer base {
  
    body {
        @apply bg-backgroundColor 
    }
    

    button {
      @apply p-3 border max-h-12 bg-buttonColor text-white rounded-md  hover:bg-buttonHover
    }

    html {
      font-family: upgrade, sans-serif, Helvetica, Arial;
    }
}


